import React from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Checkbox from "@material-ui/core/Checkbox"
import { Link, navigate } from "gatsby"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import styled from "styled-components"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { StaticImage } from "gatsby-plugin-image"
import { useAddLoginMutation } from "../components/features/api/authApi"
import { useDispatch } from "react-redux"
import { setCredentials } from "../components/features/authSlice"

// import LoadingSkelton from "../components/LoadingSkelton"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  skelton: {
    width: 300,
  },
}))

export default function SignIn() {
  const [, updateState] = React.useState()
  const dispatch = useDispatch()
  const classes = useStyles()
  const [email, setEmail] = React.useState("")
  const [pass, setPass] = React.useState("")
  const [showPassword, setShowPassword] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const token = false

  //destructure api from rtk
  const [addLogin, { data, isLoading, error, status }] = useAddLoginMutation()

  //show password
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  //submit data to addLogin rtk query
  const handleSubmit = async (event) => {
    setLoading(false)
    event.preventDefault()
    await addLogin({ identifier: email, password: pass })

    error && setLoading(true)
  }

  //check the statsu of login
  status === "fulfilled" && (
    <>
      {navigate("/dashboard")}
      {sessionStorage.setItem("token", JSON.stringify(data.jwt))}
    </>
  )

  console.log(data)

  return (
    <>
      {token ? (
        <h2>you already logged in</h2>
      ) : (
        <Wrapper>
          <div className="login_wrapper">
            <div className={classes.paper}>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <h6
                style={{
                  color: "red",
                  fontSize: "1rem",
                  fontWeight: "500",
                  marginTop: "5px",
                  marginBottom: "0",
                  textAlign: "center",
                }}
                id="error-messages"
              >
                {error && error.data.message[0].messages[0].message}
              </h6>
              <form className={classes.form} validate onSubmit={handleSubmit} id="form-validation">
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoFocus
                  value={email}
                  InputLabelProps={{
                    style: {
                      color: "#151515",
                      opacity: "0.8",
                      fontFamily: "var(--family)",
                      fontWeight: "500",
                    },
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  name="password"
                  label="Password"
                  id="password"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                  InputLabelProps={{
                    style: {
                      color: "#151515",
                      opacity: "0.8",
                      fontFamily: "var(--family)",
                      fontWeight: "500",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          id="show-hide"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <div className="label_wrapper">
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                  />
                  <Link
                    to="/forgot-password"
                    className="forgot-password"
                    variant="body2"
                  >
                    Forgot password?
                  </Link>
                </div>
                <div className="submit_wrapper">
                  <div>
                    <button
                      type="submit"
                      id="login-submit"
                      className={`submit_button${email && pass && ` active`}`}
                    >
                      {!isLoading ? "Sign In" : "loading.."}{" "}
                    </button>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      margin: "1.5rem 0",
                    }}
                  >
                    <div className="divider" />
                  </div>
                  <div style={{ display: "flex" }} className="account_wrapper">
                    <h4>Don't have an account?</h4>
                    <Link to="/create-account" className="create_an_account">
                      create an account
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="illustration">
            {/* <StaticImage
              src="../images/sign in 1.png"
              alt="certisured log in"
            /> */}
          </div>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  align-items: center;


  .login_wrapper {
    grid-area: auto/2/auto/5;
    padding: 50px 0;
    width: 100%;
    @media (max-width: 991px) {
      grid-area: 2/2/3/11;
      padding-top: 0px;
    }

    h1 {
      font-weight: 600 !important;
      font-size: 22px !important;
      color: var(--secondaryColor) !important;
      text-transform: lowercase;
    }
  }
  span {
    font-family: var(--family);
    @media (max-width: 479px) {
      font-size: 12px;
    }
  }
  .illustration {
    grid-area: auto/6/auto/11;
    padding: 50px 0;
    @media (max-width: 991px) {
      grid-area: 1/2/2/11;
      padding-bottom: 0px;
      display: flex;
      justify-content: center;
      margin-top: 2rem;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 10px !important;
    background: #f8f8f8 !important;
  }
  .MuiInputBase-input {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    /* identical to box height */

    color: #151515;

    opacity: 0.8;
  }
  .label_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    .forgot-password {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;

      /* identical to box height */

      color: #151515;
      text-decoration: none;
      opacity: 0.5;

      @media (max-width: 479px) {
        font-size: 12px;
      }
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid rgba(0, 0, 0, 0.19) !important;
  }

  .submit_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1.5rem;
    .submit_button {
      color: var(--secondaryColor);
      background: none;
      padding: 15px 25px;
      min-width: 200px;
      border: none;
      border: 3px solid #2cde80;
      box-sizing: border-box;
      border-radius: 188px;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      @media (max-width: 479px) {
        font-size: 12.9909px;
        min-width: 150px;
      }
    }
    .active {
      background: var(--thirdColor);
    }
    .divider {
      width: 329.3px;
      height: 3px;
      background: radial-gradient(
        100.86% 10936973.14% at 100% 0%,
        #000 0%,
        #000 0%,
        rgba(0, 0, 0, 0) 0.01%,
        rgba(0, 0, 0, 0.19) 45.67%,
        rgba(0, 0, 0, 0) 100%
      );
    }
    .account_wrapper {
      align-items: center;
      h4 {
        font-weight: normal;
        font-size: 16px;

        color: #151515;
        @media (max-width: 479px) {
          font-size: 16px;
        }
      }
      .create_an_account {
        text-decoration: none;
        margin-left: 5px;
      }
    }
  }
`
